<template>
  <component
    :is="props.to ? 'nuxt-link' : props.href ? 'a' : 'div'"
    :to="props.to"
    :href="props.href"
    :class="[
      'bg-neutral-50 rounded-xl focus:outline-none border border-neutral-100 overflow-hidden block'
    ]"
  >
    <div class="relative block h-full">
      <img
        v-if="props.linkImg"
        loading="lazy"
        :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
        :src="props.linkImg"
        :alt="props.imgAlt || ''"
        width="200"
        height="200"
      >
      <div class="picture-card">
        <div class="bg-gradient-to-t from-neutral-900/80 from-50%  w-full grid px-5 py-6 text-white grow-0 content-center transition-all ease-in-out duration-500 delay-100">
          <UiHeadline v-if="$slots.headline" design="h4">
            <slot name="headline" />
          </UiHeadline>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  linkImg: {
    type: String,
    default: null
  },
  imgAlt: {
    type: String,
    default: null
  }
})
</script>

<style lang="scss">
.picture-card{
  @apply flex flex-col items-center justify-end absolute text-center bottom-0 w-full h-full bg-neutral-900/0 hover:bg-neutral-900/30 transition-all ease-in-out duration-700 delay-100;
  &:hover{
    &> div{
      @apply grow;
    }
  }
}
</style>
