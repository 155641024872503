<template>
  <RuesBoxSize :size="props.item?.width" :class="[{ 'text-center': btnstartpage }]">
    <div :class="[{ 'w-auto inline-block text-center': btnstartpage }]">
      <UiButton v-if="props.type === 'Startpage'" href="/" color="primary">
        <UiLanguagePlaceholder domain="RuesButton" name="Startpage" title="testttt" />
      </UiButton>
      <UiButton v-if="props.type === 'Searchlink'" href="/search/" color="primary">
        <UiLanguagePlaceholder domain="RuesButton" name="Searchlink" />
      </UiButton>
      <UiButton v-if="props.type === 'FlexiblerSecondaryButton'" :href="props.item?.url" :title="props.item?.link_title_text" color="primary" class="w-full">
        {{ props.item?.button_text }}
      </UiButton>
    </div>
  </RuesBoxSize>
</template>

<script setup>

const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: ''
  }
})
const btnstartpage = computed(() => props.type === 'Startpage' || props.type === 'FlexiblerSecondaryButton' || props.type === 'Searchlink')
</script>

<style scoped>

</style>
