<template>
  <RuesBoxSize :size="props.item?.width">
    <img :src="props.item?.file" :alt="props.item?.alt" height="100" width="100" class="rounded-xl w-full h-auto">
    <div v-if="props.item?.license" class="text-neutral-500 text-right">
      {{ props.item?.license }}
    </div>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style lang="scss">

</style>
