<template>
  <div
    :class="[
      { 'border-neutral-50 bg-neutral-50 text-neutral-800': neutral },
      { 'border-petrol-500 bg-petrol-50 text-petrol-500': info },
      { 'border-cta-700 bg-cta-50 text-cta-700': error },
      { 'border-cta-700 bg-cta-50 text-cta-700': success },
      'border rounded-xl p-3 md:p-5']"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  }
})
const neutral = computed(() => props.design === 'neutral')
const info = computed(() => props.design === 'info')
const error = computed(() => props.design === 'error')
const success = computed(() => props.design === 'success')

</script>
