<template>
  <RuesBoxSize :size="props.item?.width">
    <UiText v-if="props.item?.params.nl_info !== ''" class="mb-1">
      {{ props.item?.params.nl_info }}
    </UiText>
    <div class="grid grid-cols-12 gap-4 p-4 border-2 border-petrol-500 rounded-xl">
      <div class="col-span-12 md:col-span-6">
        <img class="h-full w-full object-cover rounded-xl" src="https://img.travanto.de/global/tra_1666/v/sylt-startseite-min.jpg" width="100" height="100" alt="Newsletter">
      </div>
      <div class="col-span-12 md:col-span-6 grid grid-cols-1 gap-4 px-4 py-4">
        <UiHeadline design="h3" type="h2" class="rues-newsletter-headline">
          {{ t('headline') }}
        </UiHeadline>
        <UiLanguagePlaceholder domain="RuesNewsletter" name="info_text" class="block" />
        <div>
          <div class="block mb-2 font-bold">
            {{ t('list_headline') }}
          </div>
          <UiListItem class="mb-2">
            <template #icon>
              <UiIcon src="/svg/icons/check.svg" width="20" height="20" class="fill-petrol-500" />
            </template>
            {{ t('list_p_1') }}
          </UiListItem>
          <UiListItem class="mb-2">
            <template #icon>
              <UiIcon src="/svg/icons/check.svg" width="20" height="20" class="fill-petrol-500" />
            </template>
            {{ t('list_p_2') }}
          </UiListItem>
          <UiListItem class="mb-2">
            <template #icon>
              <UiIcon src="/svg/icons/check.svg" width="20" height="20" class="fill-petrol-500" />
            </template>
            {{ t('list_p_3') }}
          </UiListItem>
        </div>
        <UiHeadline type="h3" design="h4" class="rues-newsletter-headline">
          {{ t('input_headline') }}
        </UiHeadline>
        <SharedNewsletterForm />
        <UiLanguagePlaceholder domain="RuesNewsletter" name="privacy_policy" :variables="{ url: '/datenschutz' }" class="block font-light newsletter-privacy-policy" />
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup>

const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
const t = useTranslations('RuesNewsletter').translate
</script>

<style lang="scss">
.rues-newsletter-headline{
  @apply font-bold;
}
.newsletter-privacy-policy{
  a{
    @apply font-bold underline;
  }
}
</style>
