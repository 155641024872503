<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard :href="props.type === 'URLTeaser' || props.type === 'URLTeaser2' ? props.item?.link : ''" :to="props.item?.to" :design="props.item?.design">
      <template v-if="props.item?.headline" #headline>
        <UiHeadline :type="props.item?.headline_select.toLowerCase()" :design="props.item?.headline_select.toLowerCase()">
          {{ props.item?.headline }}
        </UiHeadline>
      </template>
      <template v-if="props.item?.file" #img>
        <img
          loading="lazy"
          :src="props.item?.file"
          :alt="props.item?.alt || props.item?.headline || props.item?.title"
          height="auto"
          width="auto"
          :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
        >
      </template>
      <template v-if="props.item?.lizenz || props.item?.image_license" #license>
        {{ props.item?.lizenz || props.item?.image_license }}
      </template>
      <template v-if="props.item?.icon_url" #icon>
        <img
          loading="lazy"
          :src="props.item?.icon_url"
          alt="Teaser Icon"
          height="35"
          width="35"
          :class="[ 'min-w-[35px] max-h-[35px] w-auto' ]"
        >
      </template>
      <template v-if="props.item?.title" #title>
        {{ props.item?.title }}
      </template>
      <template v-if="props.item?.description || props.item?.text" #text>
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="props.item?.description" v-html="props.item?.description" />
        <span v-if="props.item?.text" v-html="props.item?.text" />
        <!-- eslint-disable vue/no-v-html -->
      </template>
      <template v-if="props.item?.link_text" #link_text>
        <a :href="props.item?.link">{{ props.item?.link_text }}</a>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: null
  }
})
</script>

<style scoped>

</style>
