<template>
  <RuesBoxSize :size="props.item?.width" class="text-left">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <UiAccordion v-for="article in props.item?.questions" :key="article.title" class="border-b border-neutral-500">
      <template #toggle>
        {{ article.frage }}
      </template>
      <div class="pb-5 content-text-wrapper">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="article.antwort" />
        <!-- eslint-disable vue/no-v-html -->
      </div>
    </UiAccordion>
  </RuesBoxSize>
</template>

<script setup>
import useJsonLdFaq from '~/composables/useJsonLdFaq'

const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})

useJsonLdFaq().addQuestions(props.item?.questions)
</script>

<style scoped>

</style>
