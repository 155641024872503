<template>
  <slot v-if="show" />
  <slot v-if="!show" name="preview" />
</template>

<script setup lang="ts">
const props = defineProps({
  delay: {
    type: Number,
    default: 100
  }
})

const show = ref(false)
setTimeout(() => {
  show.value = true
}, props.delay)
</script>

<style scoped>
:slotted(.fade-in) {
  @apply animate-[fadeIn_0.5s_linear];

  @keyframes fadeIn {
    from {
      @apply opacity-0
    }
    to {
      @apply opacity-100
    }
  }
}
</style>
