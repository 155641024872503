<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedPictureCard :link-img="props.item?.file" :img-alt="props.item?.alt" :href="props.item?.link">
      <template v-if="props.item?.title" #headline>
        {{ props.item?.title }}
      </template>
    </SharedPictureCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style scoped>

</style>
