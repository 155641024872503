import vacationRental from '~/utils/jsonLd/vacationRental'

const useJsonLdItemListOfVacationRentals = () => {
  const productsListState = useState<object[]>(() => [])

  const setProductsList = (productsList: object[]) => {
    if (!productsList) {
      productsListState.value = null
      return
    }

    productsListState.value = productsList
  }

  const getAssembledObject = (): object | null => {
    if (!productsListState.value || productsListState.value.length === 0) {
      return null
    }

    return {
      '@type': 'ItemList',
      itemListElement: productsListState.value.map((product, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: vacationRental(product)
      }))
    }
  }

  return {
    setProductsList,
    getAssembledObject
  }
}
export default useJsonLdItemListOfVacationRentals
