<template>
  <RuesBoxSize :size="props.item?.width" class="grid grid-cols-12 gap-4">
    <UiHeadline v-if="props.item?.params?.urlaub_headline" design="h2" type="h2" class="col-span-12">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="props.item?.params.urlaub_headline" />
      <!-- eslint-enable vue/no-v-html -->
    </UiHeadline>
    <div v-for="article in articles" :key="article.id" class="col-span-12 md:col-span-4">
      <SharedCard :href="article.href">
        <template v-if="article.img_url" #img>
          <img :src="article.img_url" :alt="article.komplett" height="auto" width="auto" :class="[ 'aspect-[16/9] object-cover object-center w-full' ]">
        </template>
        <template v-if="article.img_license" #license>
          {{ article.img_license }}
        </template>
        <template v-if="article.title" #title>
          {{ article.title }}
        </template>
        <template v-if="article.text" #text>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="article.text" />
          <!-- eslint-disable vue/no-v-html -->
        </template>
      </SharedCard>
    </div>
    <template v-if="props.item?.ausstattungen2">
      <div v-for="article in articles2" :key="article" class="col-span-12 border-b border-neutral-200 pb-2">
        <a :href="article.href" class="col-span-12 text-lg font-semibold underline">{{ article.title }}</a>
        <p>{{ article.text }}</p>
      </div>
    </template>
    <div v-if="articles3?.length > 3" class="col-span-12 border border-neutral-100 rounded-xl overflow-hidden">
      <UiCollapsed>
        <template #head>
          <div class="text-base cursor-pointer py-3 px-5 relative rues-link-accordion-arrow-head hover:bg-neutral-50">
            <span>
              {{ t('more_articles') }}
            </span>
            <span class="rues-link-accordion-arrow absolute right-0 top-0 m-4">
              <UiIcon src="/svg/icons/arrow-thin.svg" width="16" height="16" alt="show more" class="fill-petrol-500" />
            </span>
          </div>
        </template>
        <template #collapse>
          <template v-if="props.item?.urlaubsseiten2">
            <div v-for="article in articles2" :key="article.id" class="text-base cursor-pointer border-t border-neutral-100 hover:bg-neutral-50">
              <a :href="article.href" class="block py-3 px-5">
                {{ article.title }}
              </a>
            </div>
          </template>
          <div v-for="article in articles3" :key="article.id" class="text-base cursor-pointer border-t border-neutral-100 hover:bg-neutral-50">
            <a :href="article.href" class="block py-3 px-5">
              {{ article.title }}
            </a>
          </div>
        </template>
      </UiCollapsed>
    </div>
  </RuesBoxSize>
</template>

<script setup>
const t = useTranslations('RuesMultiCardLinkAccordion').translate
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
const articles = props.item?.ausstattungen ?? props.item?.urlaubsseiten
const articles2 = props.item?.ausstattungen2 ?? props.item?.urlaubsseiten2
const articles3 = props.item?.ausstattungen3 ?? props.item?.urlaubsseiten3
</script>

<style lang="scss">
.rues-link-accordion-arrow{
  @apply rotate-90
}
.active{
  .rues-link-accordion-arrow-head{
    @apply font-semibold
  }
  .rues-link-accordion-arrow{
    @apply rotate-270
  }
}
</style>
