<template>
  <RuesBoxSize :size="props.item?.width" class="grid gap-y-4">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <div class="overflow-x-scroll lg:overflow-x-hidden inline-block w-full">
      <div class="lg:w-full w-[928px] flex">
        <div class="w-[70px] grow-0 pr-3 font-semibold mb-3 ">
          <span v-if="props.item?.column_type === 'number'">
            {{ props.item?.max }}
          </span>
          <span v-if="props.item?.column_type === 'price'">
            {{ props.item?.max }} €
          </span>
          <span v-if="props.item?.column_type === 'percentage'">
            {{ props.item?.max }} %
          </span>
        </div>
        <div class="grow mb-3 ">
          <div class="flex flex-row w-full h-[200px] justify-around border-x border-b border-neutral-500">
            <UiTooltip
              v-for="week in props.item?.weeks"
              :key="week.height"
              hover
              placement="top"
              class="rues-histogramm-weeks-bar-box"
              :style="`height:` + week.height + `%`"
            >
              <div
                :class="[{'bg-petrol-500' : props.item?.design === 'petrol' || props.item?.design === 'primary'}, {'bg-orange-500' : props.item?.design === 'orange' || props.item?.design === 'secondary'}, 'self-end w-full rounded-t-full align-bottom h-full']"
              />
              <template #content>
                {{ week.monday }} - {{ week.sunday }}<br>
                {{ week.chosen_column }}
                <span v-if="props.item?.column_type === 'price'"> €</span>
                <span v-if="props.item?.column_type === 'percentage'"> %</span>
              </template>
            </UiTooltip>
          </div>
          <div class="flex flex-row w-full">
            <div v-for="month in props.item?.months" :key="month.month" class="basis-0 border-r last:border-r-0 border-neutral-500" :style="`flex-grow:` + month.days">
              <span v-if="month.days > 15" class="px-2">
                <UiLanguagePlaceholder domain="RuesHistogramm" :name="`name_` + month.month" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center gap-x-2">
      <div class="flex items-center">
        <div :class="[{'bg-petrol-500' : props.item?.design === 'petrol' || props.item?.design === 'primary'}, {'bg-orange-500' : props.item?.design === 'orange' || props.item?.design === 'secondary'}, 'inline-block w-[20px] h-[20px]']" />
      </div>
      <div class="text-sm">
        <UiLanguagePlaceholder domain="RuesHistogramm" :name="props.item?.column" />
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style lang="scss">
.rues-histogramm-weeks-bar-box {
  @apply m-0 border-0 flex grow self-end;
  margin: 0 !important;
  border: 0 !important;
  & > div:first-child{
    @apply w-full h-full self-end flex border-r border-transparent border-b-0;
    border-right: 2px solid #fff;
  }
  .popper{
    @apply p-2 text-center rounded-xl;
    --popper-theme-background-color: #000;
    --popper-theme-background-color-hover: #000;
    --popper-theme-text-color: #FFF;
    --popper-theme-border-color: rgb(228 228 228 / 1);
    --popper-theme-border-width: 0;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 0.75rem;
    --popper-theme-padding: 0.5rem;
    --popper-theme-box-shadow: none;
  }
}
</style>
