<template>
  <div
    :class="[
      { 'col-span-12 md:col-span-6 lg:col-span-3' : col3 },
      { 'col-span-12 md:col-span-6 lg:col-span-4' : col4 },
      { 'col-span-12 md:col-span-6 lg:col-span-6' : col6 },
      { 'col-span-12 md:col-span-6 lg:col-span-8' : col8 },
      { 'col-span-12' : col12 },
    ]"
  >
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: Number,
    default: 12
  }
})
const col3 = computed(() => props.size === 3)
const col4 = computed(() => props.size === 4)
const col6 = computed(() => props.size === 6)
const col8 = computed(() => props.size === 8)
const col12 = computed(() => props.size === 12)
</script>

<style scoped>

</style>
