<template>
  <RuesBoxSize v-if="props.item?.fotolinks?.suchergebnisse?.length > 0 || props.item?.objekte?.length > 0" :size="props.item?.width" class="text-left grid grid-cols-1 gap-y-4 product-carousel">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <UiHeadline v-if="props.item?.headline === '' && props.type === 'ObjektTeaser4'" type="h2" design="h2">
      {{ defaultHeadline }}
    </UiHeadline>
    <UiHeadline v-if="props.item?.element === 'Fotolinks'" type="h2" design="h2">
      {{ t('FotolinksHeadline') }}
    </UiHeadline>
    <div class="min-h-[230px] lg:min-h-[210px] xl:min-h-[240px] 1xl:min-h-[240px] 2xl:min-h-[230px] select-none">
      <div v-if="!props.skeleton && loaded" class="relative">
        <swiper
          :modules="modules"
          :breakpoints="{
            '768': {
              slidesPerView: 2.3
            },
            '976': {
              slidesPerView: 3
            },
          }"
          :slides-per-view="1.3"
          :space-between="0"
          :loop="true"
          direction="horizontal"
          :lazy="true"
          class="h-full w-full"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="article in props.item?.fotolinks?.suchergebnisse" :key="article.id" class="w-full px-2 py-3">
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="false"
              class="w-full sm:hidden block"
              :item="article"
            />
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="true"
              class="w-full hidden sm:block"
              :item="article"
            />
          </swiper-slide>
          <swiper-slide v-for="article in props.item?.objekte" :key="article.id" class="w-full px-2 py-3">
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="false"
              class="w-full sm:hidden block"
              :item="article"
              :region-distance-to="props.item?.h2_region_ort"
            />
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="true"
              class="w-full hidden sm:block"
              :item="article"
              :region-distance-to="props.item?.h2_region_ort"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" @click.stop.prevent="swiperRef.slidePrev()" />
        <div class="swiper-button-next" @click.stop.prevent="swiperRef.slideNext()" />
      </div>
      <div v-show="props.skeleton || !loaded" class="flex flex-nowrap gap-x-4 w-full overflow-hidden">
        <SkeletonSharedObjectCard design="objectslider" class="w-[75%] md:w-[41.7%] lg:w-[33.3333%]" />
        <SkeletonSharedObjectCard design="objectslider" class="md:w-[41.7%] lg:w-[33.3333%] hidden md:block" />
        <SkeletonSharedObjectCard design="objectslider" class="lg:w-[33.3333%] hidden lg:block" />
        <SkeletonSharedObjectCard design="objectslidersmall" class="w-[22%] md:w-[12.2%] block lg:hidden" />
      </div>
    </div>
    <div v-if="props.item?.element !== 'Fotolinks' && props.type !== 'ObjektTeaser4'" class="text-center md:text-right">
      <div class="flex md:inline-block">
        <UiButton href="#" color="primary" class="w-full md:w-auto">
          {{ t('show_all_offers') }}
        </UiButton>
      </div>
    </div>
    <div v-if="props.type === 'ObjektTeaser4'" class="text-center md:text-right">
      <div class=" flex md:inline-block">
        <UiMaskedLink :href="props.item?.serp_url" :external="true">
          <UiButton color="primary" class="w-full md:w-auto" @click="save">
            <template v-if="props.item?.headline">
              {{ t('show_all_offers_headline', { headline: props.item?.headline }) }}
            </template>
            <template v-else>
              {{ t('show_all_offers_headline', { headline: defaultHeadline }) }}
            </template>
          </UiButton>
        </UiMaskedLink>
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import { useSearchParamsStore } from '~~/stores/searchParams'
import useJsonLdItemListOfVacationRentals from '~/composables/useJsonLdItemListOfVacationRentals'

const t = useTranslations('RuesProductCarousel').translate

const props = defineProps({
  skeleton: {
    type: Boolean,
    default: true
  },
  item: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: ''
  },
  searchParams: {
    type: Object,
    default: null
  }
})
const loaded = ref(false)
const swiperRef = ref()
const onSwiper = (swiper) => {
  swiperRef.value = swiper
}

const defaultHeadline = t.value('ObjektTeaserHeadline') + props.item?.h2_urlaubskategorie + props.item?.h2_merkmal + props.item?.h2_multi +
  props.item?.preposition + props.item?.h2_region_ort
const searchParamsStore = useSearchParamsStore()
const save = () => {
  const mergedParams = {
    ...props.searchParams,
    ...(props.item?.serp_url_params ?? {})
  }
  searchParamsStore.$patch(mergedParams)
}
if (useJsonLdItemListOfVacationRentals().getAssembledObject() === null) {
  useJsonLdItemListOfVacationRentals().setProductsList(props.item?.objekte)
}

const modules = [Navigation]

if (import.meta.client) {
  onMounted(() => {
    loaded.value = true
  })
}

</script>

<style lang="scss">

</style>
<style src="./scss/RuesProductCarousel.scss" scoped></style>
