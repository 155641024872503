<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <a :href="props.item?.type === 'reviews' ? '/search/' : props.item?.objekt_bewertung?.url">
          <img
            v-if="props.item?.type == 'reviews'"
            loading="lazy"
            src="https://img.travanto.de/img/travanto/teaser/teaser_trustpilot.jpg"
            alt="Fahrrad"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
          >
          <img
            v-if="props.item?.type == 'singel_review'"
            loading="lazy"
            :src="'https://img.travanto.de' + props.item?.objekt_bewertung?.bild"
            :alt="props.item?.alt"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
          >
        </a>
      </template>
      <template #title>
        <UiLanguagePlaceholder v-if="props.item?.type == 'reviews'" domain="RuesCardReviews" name="headline_reviews" />
        <UiLanguagePlaceholder v-if="props.item?.type == 'singel_review'" domain="RuesCardReviews" name="headline_singel_review" />
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <div>
            <SharedRating :item="props.item?.type === 'reviews' ? props.item?.bewertung?.durchschnitt : parseFloat(props.item?.objekt_bewertung?.sterne)" />
          </div>
          <div v-if="props.item?.type == 'reviews'">
            <UiLanguagePlaceholder domain="RuesCardReviews" name="reviews" :variables="{ objects: props.item?.bewertung.objekte, reviews: props.item?.bewertung.anzahl, portal: props.item?.portalname }" />
          </div>
          <div v-if="props.item?.type == 'singel_review'">
            <div class="font-semibold">
              {{ props.item?.objekt_bewertung?.hausname_shortened }}
            </div>
            <div>
              <span class="line-clamp-2">» {{ props.item?.objekt_bewertung?.text_shortened }} «</span>
            </div>
          </div>
          <div class="text-base self-end">
            <UiButton color="primary" :href="props.item?.type === 'reviews' ? '/search/' : props.item?.objekt_bewertung?.url">
              <UiLanguagePlaceholder v-if="props.item?.type == 'reviews'" domain="RuesCardReviews" name="btn_reviews" />
              <UiLanguagePlaceholder v-if="props.item?.type == 'singel_review'" domain="RuesCardReviews" name="btn_singel_review" />
            </UiButton>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style lang="scss">

</style>
