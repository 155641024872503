<template>
  <component
    :is="props.to ? 'nuxt-link' : props.href ? 'a' : 'div'"
    :to="props.to"
    :href="props.href"
    :new-tab="isNewTab"
    :class="[
      'bg-neutral-50 rounded-xl focus:outline-none border border-neutral-100 overflow-hidden h-full flex flex-col',
    ]"
  >
    <div v-if="$slots.headline" class="grid gap-y-2 px-5 py-3">
      <slot name="headline" />
    </div>
    <div v-if="$slots.img" class="relative">
      <div
        :class="['w-full overflow-hidden']"
      >
        <slot name="img" />
      </div>
      <div v-if="$slots.title && deluxe" class="text-lg font-semibold text-white absolute bottom-0 w-full bg-neutral-900/75 flex justify-start items-center gap-x-2 px-5 py-2">
        <div v-if="$slots.icon">
          <slot name="icon" />
        </div>
        <div>
          <slot name="title" />
        </div>
      </div>
    </div>
    <div v-if="$slots.license" class="text-neutral-300 text-sm text-right px-5 pt-1">
      <slot name="license" />
    </div>
    <div class="grid gap-y-2 px-5 py-3 h-full grow">
      <div v-if="$slots.title && simpel" class="text-lg font-semibold">
        <slot name="title" />
      </div>
      <div v-if="$slots.text" class="text-sm SharedCard-text">
        <slot name="text" />
      </div>
      <div v-if="$slots.link_text" class="text-sm text-right underline">
        <slot name="link_text" />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  design: {
    type: String,
    default: 'default'
  }
})
const isNewTab = ref(false)
if (import.meta.client) {
  const userAgent = window.navigator.userAgent
  isNewTab.value = !/(iPad|iPhone)/.test(userAgent)
}
const simpel = computed(() => props.design === 'default' || props.design === 'startseite-teaser')
const deluxe = computed(() => props.design === 'startseite-teaser-big')
</script>

<style scoped>
:deep(.SharedCard-text){
  &> a{
    @apply text-petrol-500;
  }
  &> span, &> div{
    &> a{
      @apply text-petrol-500;
    }
  }
}
</style>
