<template>
  <RuesBoxSize :size="props.item?.width" class="relative py-4">
    <UiHighlight design="neutral" class="text-left rues-headline">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="props.item?.text" />
    </UiHighlight>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style scoped lang="scss">
  .rues-headline{
    h1{
      @apply text-3xl md:text-5xl;
    }
    h2{
      @apply text-2xl md:text-4xl;
    }
    h3{
      @apply text-xl md:text-2xl;
    }
    h4{
      @apply text-lg;
    }
    h1, h2, h3, h4 ,h5, h6{
      @apply font-semibold mb-6;
    }
  }
  ul, li, menu {
    @apply my-[10px] ml-4 list-disc;
  }
  .relative {
    position: relative;
  }
</style>
