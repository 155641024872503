<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <a :href="props.item?.neuestes_objekt?.url">
          <img
            loading="lazy"
            :src="props.item?.neuestes_objekt?.thumb"
            alt="Lastminute"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
          >
        </a>
      </template>
      <template #title>
        <UiLanguagePlaceholder domain="RuesCardNewProduct" name="headline_new" :variables="{ portal: props.item?.portalname}" />
        <div v-if="props.item?.hausname">
          {{ props.item?.neuestes_objekt?.hausname }}
        </div>
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <div>
            <div>
              <UiLanguagePlaceholder domain="RuesCardNewProduct" name="guests" :variables="{ guests: props.item?.neuestes_objekt?.personen }" />
            </div>
            <div>
              <UiLanguagePlaceholder domain="RuesCardNewProduct" name="rooms" :variables="{ rooms: props.item?.neuestes_objekt?.zimmer ?? '' }" />
            </div>
            <div>
              <UiLanguagePlaceholder domain="RuesCardNewProduct" name="ort" :variables="{ ort: props.item?.neuestes_objekt?.ortsname }" />
            </div>
          </div>
          <div class="rues-new-product-price">
            <UiLanguagePlaceholder domain="RuesCardNewProduct" name="price" :variables="{ price: props.item?.neuestes_objekt?.preis_min + props.item?.neuestes_objekt?.waehrung }" />
          </div>
          <div class="text-base self-end">
            <UiButton color="primary" :href="props.item?.neuestes_objekt?.url">
              <UiLanguagePlaceholder domain="RuesCardNewProduct" name="btn_new" />
            </UiButton>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>

<style lang="scss">
.rues-new-product-price{
  span{
    @apply font-semibold text-xl
  }
}
</style>
