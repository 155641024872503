<template>
  <RuesBoxSize :size="props.item?.width" class="text-left grid grid-cols-1 gap-y-4">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <div class="grid grid-cols-12 gap-x-4 gap-y-8">
      <div v-for="article in props.item?.first_part_cities" :key="article.ort" class="col-span-12 sm:col-span-6 lg:col-span-4">
        <a :href="article.url" :title="article.h1">
          <div class="text-neutral-500">
            <UiLanguagePlaceholder domain="RuesHeadlineLinkCollection" name="headline" />
          </div>
          <span class="text-neutral-900 focus:text-petrol-500 hover:underline">{{ article.ort }}</span>
        </a>
      </div>
    </div>
    <div v-if="allRegions.isOpen && props.item?.second_part_cities.length > 0" class="grid grid-cols-12 gap-x-4 gap-y-8">
      <div v-for="article in props.item?.second_part_cities" :key="article.ort" class="col-span-12 sm:col-span-6 lg:col-span-4">
        <a :href="article.url" :title="article.h1">
          <div class="text-neutral-500">
            <UiLanguagePlaceholder domain="RuesHeadlineLinkCollection" name="headline" />
          </div>
          <span class="text-neutral-900 focus:text-petrol-500 hover:underline">{{ article.ort }}</span>
        </a>
      </div>
    </div>
    <span v-if="props.item?.second_part_cities.length > 0" class="cursor-pointer text-petrol-500 ml-2" @click="showOrHideRegions">
      <span v-if="!allRegions.isOpen">
        <UiLanguagePlaceholder domain="RuesHeadlineLinkCollection" name="show_more" />
      </span>
      <span v-if="allRegions.isOpen">
        <UiLanguagePlaceholder domain="RuesHeadlineLinkCollection" name="show_less" />
      </span>
      <svg :style="allRegions.isOpen ? 'transform: rotate(180deg)' : ''" xmlns="http://www.w3.org/2000/svg" width="12.5" height="6.962" viewBox="0 0 12.5 6.962">
        <path
          id="chevron-right"
          d="M.461.311a.461.461,0,0,1,.653,0L6.652,5.848a.462.462,0,0,1,0,.653L1.114,12.04a.462.462,0,1,1-.653-.653L5.673,6.175.461.964a.461.461,0,0,1,0-.653Z"
          transform="translate(12.425 -0.075) rotate(90)"
          fill="#179396"
          stroke="#179396"
          stroke-width="0.5"
          fill-rule="evenodd"
        />
      </svg>
    </span>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
const allRegions = reactive({
  isOpen: false
})
function showOrHideRegions () {
  allRegions.isOpen = !allRegions.isOpen
}
</script>

<style scoped>

</style>
