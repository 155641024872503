const useJsonLdFaq = () => {
  const questionsState = useState<object[]>(() => [])

  const addQuestions = (questions: object[]) => {
    if (!questions) {
      return
    }

    questionsState.value.push(...questions)
  }

  const getAssembledObject = (): object|null => {
    if (!questionsState.value || questionsState.value.length === 0) {
      return null
    }

    return {
      '@type': 'FAQPage',
      mainEntity: questionsState.value.map(question => ({
        '@type': 'Question',
        name: question.frage,
        acceptedAnswer: {
          '@type': 'Answer',
          text: question.antwort
        }
      }))
    }
  }

  return {
    addQuestions,
    getAssembledObject
  }
}

export default useJsonLdFaq
