<template>
  <RuesBoxSize :size="props.item?.width ?? 12" class="grid grid-cols-3 gap-4 content-center place-items-center text-center place-content-center" style="margin-top: -20px">
    <div class="break-words max-w-full">
      <a href="https://www.deutscher-ferienhausverband.de/zertifikat/travanto/" target="_blank" rel="nofollow">
        <UiIcon src="/svg/logo/dfv_siegel_umriss.svg" width="90" height="90" class="fill-[#87AA1D] inline-block" />
        <div class="font-semibold text-neutral-600 text-xs md:text-lg mt-3 break-words">
          <UiLanguagePlaceholder domain="RuesTrust" name="ferienhausverband" />
        </div>
      </a>
    </div>
    <div v-if="props.trustpilotBox" class="break-words max-w-full">
      <!-- eslint-disable vue/no-v-html -->
      <div class="start-vsm-min-trustpilot" v-html="props.trustpilotBox" />
      <!-- eslint-disable vue/no-v-html -->
    </div>
    <div v-if="!props.trustpilotBox" class="break-words max-w-full">
      <UiIcon src="/svg/icons/20-jahre.svg" width="90" height="90" class="fill-petrol-500 inline-block max-w-full" />
      <div class="font-semibold text-neutral-600 text-xs md:text-lg mt-3 break-words">
        <UiLanguagePlaceholder domain="RuesTrust" name="experience" />
      </div>
    </div>
    <div class="break-words max-w-full">
      <a v-if="travanto" href="https://servicevalue.de/rankings/online-buchung/" target="_blank" rel="nofollow">
        <img
          loading="lazy"
          class="rues-trust-column-welt"
          src="https://img.travanto.de/travanto/img/Siegel_Online_Buchung_SEHR_HOHE_2023_travanto_de.png"
          height="180"
          width="153"
          alt="Sehr Hohe Kundenzufriedenheit"
        >
      </a>
      <div v-if="!travanto">
        <UiIcon src="/svg/icons/nights-icon.svg" width="90" height="90" class="fill-petrol-500 inline-block max-w-full" />
        <div class="font-semibold text-neutral-600 text-xs md:text-lg mt-3 break-words">
          <UiLanguagePlaceholder domain="RuesTrust" name="overnights" />
        </div>
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  trustpilotBox: {
    type: String,
    default: null
  }
})
onMounted(() => {
  const script = document.createElement('script')
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js'
  script.async = true
  document.body.appendChild(script)
})
const travanto = computed(() => props.item?.portal === 'travanto')
</script>

<style lang="scss" scoped>
  .rues-trust-column-welt{
    @apply w-full h-auto max-w-[150px] md:w-[153px] md:max-w-full;
  }
  @media screen and (max-width: 767px) {
    :deep(.trustpilot-widget) {
      height: 80px;
    }
  }
</style>
