<template>
  <RuesBoxSize :size="props.item?.width" class="grid grid-cols-12 gap-4">
    <UiHeadline v-if="props.item?.params?.urlaub_headline" design="h2" type="h2" class="col-span-12">
      {{ props.item?.params.urlaub_headline }}
    </UiHeadline>
    <div v-for="article in props.item?.ausstattungen" :key="article.id" :class="['col-span-12', specialContainer ? 'md:col-span-6' : 'md:col-span-4']">
      <SharedCard :href="article.href">
        <template v-if="article.img_url" #img>
          <img :src="article.img_url" :alt="article.komplett ? article.komplett : ''" height="auto" width="auto" :class="[ 'aspect-[16/9] object-cover object-center w-full' ]">
        </template>
        <template v-if="article.img_license" #license>
          {{ article.img_license }}
        </template>
        <template v-if="article.title" #title>
          {{ article.title }}
        </template>
        <template v-if="article.text" #text>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="article.text" />
          <!-- eslint-disable vue/no-v-html -->
        </template>
      </SharedCard>
    </div>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  specialContainer: {
    type: Boolean,
    default: false
  }
})
</script>

<style scoped>

</style>
