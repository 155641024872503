<template>
  <RuesBoxSize :size="props.item?.width">
    <div v-if="isMapVisible" style="height:100%; width:100%">
      <client-only>
        <l-map ref="map" :use-global-leaflet="false" :zoom="11">
          <l-tile-layer
            url="https://tiles.travanto.de/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          />
          <l-control-zoom :position="zoomControlPosition" />
        </l-map>
      </client-only>
    </div>
  </RuesBoxSize>
</template>

<script setup>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LControlZoom } from '@vue-leaflet/vue-leaflet'

const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})

const isMapVisible = ref(false) // Flag to determine if the map should be visible
const zoomControlPosition = 'topright' // Set the position to top right
// const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors';

const map = ref()

onMounted(() => {
  watchEffect(() => {
    showMap()

    if (!map.value) {
      return
    }
    // const center = reactive([e.n, e.e])
    const bounds = [
      { lat: props.item?.limits.s, lng: props.item?.limits.w },
      { lat: props.item?.limits.n, lng: props.item?.limits.e }
    ]

    map.value.leafletObject?.fitBounds(bounds)
  })
})
const showMap = () => {
  isMapVisible.value = true
}
</script>

<style lang="scss">
 .leaflet-container {
   @apply aspect-[16/9] rounded-xl
 }
</style>
