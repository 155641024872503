<template>
  <RuesBoxSize v-if="!sidebar" :size="props.item?.width" class="text-left grid grid-cols-1 gap-y-4">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <UiHeadline v-if="props.item?.params?.urlaub_headline" type="h2" design="h2">
      {{ props.item?.params.urlaub_headline }}
    </UiHeadline>
    <UiHeadline v-if="props.type === 'GEO'" type="h2" design="h2">
      {{ props.item?.params?.geo_headline }}
    </UiHeadline>
    <div class="grid grid-cols-12 gap-x-4 gap-y-4">
      <div v-for="article in props.item?.links" :key="article.text" class="col-span-12 sm:col-span-6 md:col-span-4">
        <a v-if="props.type === 'Liste' || props.type === 'TAG'" :href="article.link" class="text-neutral-900 hover:text-petrol-500 hover:underline" :title="article.title">{{ article.text }}</a>
        <a
          v-if="props.type === 'GEO'"
          :href="article.href"
          class="text-neutral-900 hover:text-petrol-500 hover:underline"
          :title="'Ferienwohnungen ' + article.komplett"
          :rel="article.rel"
        >{{ article.name }}</a>
      </div>

      <div v-for="article in props.item?.ausstattungen" :key="article.title" class="col-span-12 sm:col-span-6 md:col-span-4">
        <a
          :href="article.href"
          class="text-neutral-900 hover:text-petrol-500 hover:underline"
          :rel="article.rel"
        >{{ article.title }}</a>
      </div>
    </div>
  </RuesBoxSize>
  <div v-else class="col-span-12">
    <h2 class="text-3xl font-semibold mb-2">
      <UiLanguagePlaceholder domain="RuesSidebar" name="latest_articles" />
    </h2>
    <ul>
      <li v-for="(article, index) in firstThreeAusstattungen" :key="article.title" :class="{'border-b border-neutral-200 py-2': index !== firstThreeAusstattungen.length - 1, 'py-2': index === firstThreeAusstattungen.length - 1}">
        <a :href="article.href" class="text-neutral-900 hover:text-neutral-600 underline" :rel="article.rel">{{ article.title }}</a>
      </li>
    </ul>
    <UiAccordion class="border border-neutral-300 rounded-lg mt-2">
      <template #toggle>
        <div class="px-4">
          <UiLanguagePlaceholder domain="RuesSidebar" name="more_articles" />
        </div>
      </template>
      <div v-for="article in remainingAusstattungen" :key="article.title" class="px-4 py-2 content-text-wrapper border-t border-neutral-300">
        <!-- eslint-disable vue/no-v-html -->
        <a :href="article.href" v-html="article.title" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </UiAccordion>
  </div>
</template>

<script setup>

const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: null
  },
  sidebar: {
    type: Boolean,
    default: false
  }
})
const firstThreeAusstattungen = computed(() => {
  return props.item?.ausstattungen?.slice(0, 3) || []
})

const remainingAusstattungen = computed(() => {
  return props.item?.ausstattungen?.slice(3) || []
})
</script>

<style scoped>

</style>
